<template>
  <v-app style="background: #ecf0f5">
    <Header/>
    <v-content>
      <router-view></router-view>
    </v-content>
    <Footer></Footer>
  </v-app>
</template>

<script>
import Header from '../components/Header'
import Footer from '../components/Footer'

export default {
  name: 'App',
  components: {
    Header,
    Footer
  }

}
</script>

<style scoped>
  html,
  body {
    font-weight: 500;
    font-size: 14px;
    background-color: red;
  }
</style>
